import React from 'react'
import { List, Icon } from 'antd-mobile'
import { Link } from 'react-router-dom'
import ImageArea from './imageArea'
import EbookArea from './ebookArea'
import VideoArea from './videoArea'
import { getOrgDetail } from 'api/document'
import Wechat from 'utils/weixin'
import Photo from 'components/Photo'

const Item = List.Item
// const Brief = Item.Brief

class Main extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    // 幕布相关
    this.touchEnd = this.touchEnd.bind(this)
    this.touchStart = this.touchStart.bind(this)
    this.screenPictureTouchEnd = this.screenPictureTouchEnd.bind(this)
    this.screenPictureTouchStart = this.screenPictureTouchStart.bind(this)
  }

  componentDidMount () {
    this.getOrgDto()
  }

  getOrgDto () {
    const { oid } = this.props.match.params
    getOrgDetail({ id: oid }).then((res) => {
      document.title = res.data.name
      this.setState({
        orgData: res.data
      }, () => this.initMenu())
      this.weChatShare(res.data)
    })
  }

  // 设置微信分享
  weChatShare (data) {
    const { pathname, search } = this.props.location
    let title = data.name
    let img = `${data.logoUrl}?x-oss-process=image/resize,w_100`
    let content = data.description
    let link = `http://m.chntong.net${pathname}${search}`
    Wechat.initShareMenu(title, img, content, link)
  }

  initMenu () {
    this.refs.screenArea.oncontextmenu = function () { return false } // 阻止绑定dom弹出右键菜单
    this.refs.screenPicture.oncontextmenu = function () { return false } // 阻止绑定幕布弹出右键菜单
  }

  // 隐藏区域开始
  touchStart () {
    const oid = this.props.location.pathname.split('/')[1]
    if (oid === '100004') {
      this.longPressItemTimeout = setTimeout(() => { // 设置定时器3秒后触发
        this.refs.screenPicture.className = 'block ScreenPictureFadeIn'
      }, 6000)
    } else {
      this.longPressItemTimeout = setTimeout(() => { // 设置定时器3秒后触发
        this.refs.screenPicture.className = 'block ScreenPictureFadeIn'
      }, 3000)
    }
  }
  // 隐藏区域结束
  touchEnd () {
    // 定时器如果在3秒钟内松开，则取消
    clearTimeout(this.longPressItemTimeout)
  }

  // 图片区域开始
  screenPictureTouchStart () {
    const oid = this.props.location.pathname.split('/')[1]
    if (oid === '100004') {
      this.longPressPictureTimeout = setTimeout(() => { // 设置定时器6秒后触发
        this.refs.screenPicture.className = 'ScreenPictureFadeOut'
        setTimeout(() => {
          this.refs.screenPicture.className = 'block-none'
        }, 950)
        document.body.scrollTop = document.documentElement.scrollTop = 0
      }, 6000)
    } else {
      this.longPressPictureTimeout = setTimeout(() => { // 设置定时器6秒后触发
        this.refs.screenPicture.className = 'ScreenPictureFadeOut'
        setTimeout(() => {
          this.refs.screenPicture.className = 'block-none'
        }, 950)
        document.body.scrollTop = document.documentElement.scrollTop = 0
      }, 3000)
    }
  }
  // 图片区域结束
  screenPictureTouchEnd () {
    clearTimeout(this.longPressPictureTimeout)
  }

  screenPictureArea () {
    // 领导欢迎目标隐藏点击区域
    return (
      <div style={{ width: 200, height: 100, position: 'absolute', top: 0, left: 0, zIndex: '99999' }}
        ref='screenArea'
        onTouchStart={(e) => this.touchStart(e)}
        onTouchEnd={this.touchEnd}
      />
    )
  }

  render () {
    const { oid } = this.props.match.params
    const { orgData } = this.state
    if (!orgData) return null
    return (
      <div className='home'>
        <img // 幕布图片
          ref='screenPicture'
          onTouchStart={this.screenPictureTouchStart}
          onTouchEnd={this.screenPictureTouchEnd}
          src={orgData.mobileBg}
          className='block-none' alt='' style={{ zIndex: '99999999', width: '100vw', height: '100vh', position: 'sticky', top: 0, bottom: 0, left: 0, right: 0 }}
        />
        {this.screenPictureArea()}
        {/* 头部简介 */}
        <Link to={`/${oid}/home/intro`}>
          <header className='flex-row p-m bg-white cross-center'>
            <div className='m-r-m flex-column flex1'>
              {orgData.description
                ? <div className='text-line-5 p-l-s text-height-15' style={{ wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: orgData.description.replace(/\n/ig, '<br />') }} />
                : null}
            </div>
            <img src={orgData.logoUrl + '?x-oss-process=image/resize,w_300'} alt='logo' style={{ objectFit: 'contain', height: '33vw', width: '33vw' }} />
            {/* <div style={{ width: '35%' }}>
              <Photo src={orgData.logoUrl + '?x-oss-process=image/resize,w_300'} radius={3} border />
            </div> */}
          </header>
        </Link>
        {/* 主体栏目 */}
        <article>
          {/* 电子书 */}
          <section className='m-t-m'>
            <Link to={`/${oid}/home/ebooks`}>
              <List>
                <Item extra={<span className='flex-row cross-center text-right pull-right'>更多<Icon type='right' /></span>}>
                  <span className='h3'>电子书资料</span>
                </Item>
              </List>
            </Link>
            <EbookArea />
          </section>
          {/* 图文 */}
          <section className='p-t-m'>
            <Link to={`/${oid}/home/images`}>
              <List>
                <Item extra={<span className='flex-row cross-center text-right pull-right'>更多<Icon type='right' /></span>}>
                  <span className='h3'>图文资料</span>
                </Item>
              </List>
            </Link>
            <ImageArea />
          </section>
          {/* 视频 */}
          <section className='p-t-m'>
            <Link to={`/${oid}/home/videos`}>
              <List>
                <Item extra={<span className='flex-row cross-center text-right pull-right'>更多<Icon type='right' /></span>}>
                  <span className='h3'>音视频资料</span>
                </Item>
              </List>
            </Link>
            <VideoArea />
          </section>
        </article>
      </div>
    )
  }
}

export default Main
