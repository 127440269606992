import React from 'react'
import { Route, Switch } from 'react-router-dom'
import TopicDto from './dto'
import Main from './main'
import TopicItemCarousel from './TopicItemCarousel'
import MainTabBar from 'components/MainTabBar'

class Index extends React.Component {
  render () {
    return (
      <div className='p-b-50'>
        <Switch>
          <Route path='/:oid/topic' exact component={Main} />
          <Route path='/:oid/topic/detail/:topicId' component={TopicDto} />
          <Route path='/:oid/topic/carousel' exact component={TopicItemCarousel} />
        </Switch>
        <MainTabBar {...this.props} />
      </div>
    )
  }
}

export default Index
