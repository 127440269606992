import React from 'react'
import { Route, Switch } from 'react-router-dom'
import MainTabBar from 'components/MainTabBar'
import EbookDto from 'components/ThemeDto/EbookDto'
import ImagesDto from 'components/ThemeDto/ImageDto'
import VideoDto from 'components/ThemeDto/VideoDto'
import Main from './main'

class Index extends React.Component {
  render () {
    return (
      <div className='p-b-50'>
        <Switch>
          <Route path='/:oid/search/:type' exact component={Main} />
          <Route path='/:oid/search/ebooks/:id' component={EbookDto} />
          <Route path='/:oid/search/images/:id' component={ImagesDto} />
          <Route path='/:oid/search/videos/:id' component={VideoDto} />
        </Switch>
        <MainTabBar {...this.props} />
      </div>
    )
  }
}

export default Index
