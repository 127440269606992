import React from 'react'
import PropTypes from 'prop-types'
// import musicBG from './musicBG.png'
import musicBG from 'images/heijiao.jpg'
import bofang from './player.png'
import bofang1 from './player1.png'
import styles from './styles.less'

class Photo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  renderPadding () {
    const { ratio } = this.props
    let pt = 100
    // 没有http开头，且第一个字符是数字的，视为不合法的远程图片
    switch (ratio) {
      case '1b1':
        pt = 100
        break
      case '4b3':
        pt = 75
        break
      case '16b9':
        pt = 56.25
        break
      case '3b1':
        pt = 36
        break
      case '2b3':
        pt = 150
        break
      case '3b4':
        pt = 133.33
        break
      default:
        pt = 100
        break
    }
    return pt
  }

  render () {
    const { src, isAudio, children, innerClass, radius, canPlay, border } = this.props
    let wrapStyle = Object.assign({
      paddingTop: `${this.renderPadding()}%`,
      background: `url(${isAudio ? musicBG : src}) no-repeat`,
      height: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      overflow: 'hidden'
    })
    if (radius) { wrapStyle.borderRadius = `${radius}${typeof radius === 'number' ? 'px' : ''}` }
    if (border) { wrapStyle.border = '1px solid #ccc' }
    return (
      <div style={wrapStyle} className={`${styles.Photo_component} relative_remark`}>
        {canPlay ? <img src={isAudio ? bofang1 : bofang} alt='' className={`${styles.player} opacity7`} /> : null}
        {children ? <div className={`${styles.inner} ${innerClass || ''}`}>{children}</div> : null}
      </div>
    )
  }
}

Photo.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  src: PropTypes.string, // 图片路径
  ratio: PropTypes.oneOf(['1b1', '4b3', '16b9', '3b1', '2b3', '3b4']), // 尺寸比例
  radius: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]), // 圆角
  innerClass: PropTypes.string, // 内部class
  border: PropTypes.bool, // 是否有边框
  canPlay: PropTypes.bool, // 是否是播放文件
  // imageOnly: PropTypes.bool, // 是否只显示图片，在轮播时候不能用relative
  isAudio: PropTypes.bool // 是否是音频文件
}

Photo.defaultProps = {
  src: '',
  ratio: '1b1',
  radius: 0,
  // imageOnly: false,
  border: false,
  canPlay: false,
  isAudio: false
}

export default Photo
