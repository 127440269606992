import React from 'react'
import { getTopicDetail } from 'api/document'
// import { Link } from 'react-router-dom'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import Photo from 'components/Photo'
import Vutils from 'utils/video'
import { Toast } from 'antd-mobile'
import reactMixin from 'react-mixin'
import InfiniteScrollMixin from 'mixins/InfiniteScrollMixin'
import Wechat from 'utils/weixin'

class TopicDto extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    this.getDeatil()
  }

  getDeatil () {
    const { topicId, oid } = this.props.match.params
    getTopicDetail({ topicId: topicId }).then((res) => {
      if (res.code === 400) {
        Toast.fail('当前专题被删除或者不存在，已跳转至专题列表', 3)
        this.props.history.replace(`/${oid}/topic`)
      }
      this.weChatShare(res.data)
      this.setState({
        data: res.data
      })
    })
  }

  // 设置微信分享
  weChatShare (data) {
    const { pathname, search } = this.props.location
    let title = data.topicName
    let img = `${data.topicImage}?x-oss-process=image/resize,w_100`
    let content = data.topicDesc
    let link = `http://m.chntong.net${pathname}${search}`
    Wechat.initShareMenu(title, img, content, link)
  }

  goToCarousel (list, index) {
    const { oid } = this.props.match.params
    this.props.history.push({
      pathname: `/${oid}/topic/carousel`,
      state: {
        current: index,
        list: list
      }
    })
  }

  renderDtoImg (item) {
    if (item.infoType === 1) {
      return (
        <div style={{ width: '15vw' }}>
          <Photo src={item.firstPage + '?x-oss-process=image/resize,w_300'} ratio={'2b3'} border />
        </div>
      )
    } else if (item.infoType === 2) {
      return (
        <div style={{ width: '20vw' }}>
          <Photo src={item.infoUrl + '?x-oss-process=image/resize,w_300'} border />
        </div>
      )
    } else if (item.infoType === 3) {
      return (
        <div style={{ width: '30vw' }}>
          <Photo
            src={Vutils.isAudio(item.infoUrl) ? '' : item.infoUrl + '?x-oss-process=video/snapshot,t_3000,f_jpg,w_0,h_0'}
            ratio={'16b9'}
            radius={5}
            canPlay
            isAudio={Vutils.isAudio(item.infoUrl)} />
        </div>
      )
    }
  }

  renderDetail () {
    const { data } = this.state
    // const { oid } = this.props.match.params
    if (!data) return null
    const baseList = data.baseInfoList
    const list = baseList.length && baseList.map((item, index) => {
      return (
        <TimelineEvent key={item.uniqueInfoId}
          title={<div className='text-dark h4'>{item.infoTakeTime ? item.infoTakeTime.split(' ')[0] : null}</div>}
          bubbleStyle={index === 0
            ? { width: 20, height: 20, left: 6, border: 'none', background: 'rgb(136,113,66)' }
            : { width: 15, height: 15, left: 8, border: 'none', background: '#ccc' }}
        >
          {/* <Link to={`/${oid}/topic/${item.infoType === 1 ? 'ebooks' : item.infoType === 2 ? 'images' : 'videos'}/${item.uniqueInfoId}`}> */}
          <div className='flex-row' onClick={() => this.goToCarousel(baseList, index)}>
            <div className='text-darker h3 text-bolder text-line-2 flex1'>{item.infoTheme}</div>
            {this.renderDtoImg(item)}
          </div>
          {/* </Link> */}
        </TimelineEvent>
      )
    })

    return <Timeline>{list}</Timeline>
  }

  render () {
    return (
      <div className='bg-white' ref='scrollcontainer'>
        {this.renderDetail()}
      </div>
    )
  }
}

reactMixin(TopicDto.prototype, InfiniteScrollMixin)

export default TopicDto
