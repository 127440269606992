/* eslint-disable array-callback-return */
import wx from 'weixin-js-sdk'
import { wxSignature } from 'api/document'

window.isWxReady = false
// let weConfig
let APIS = [
  'previewImage', // 预览图片接口
  'showMenuItems', // 批量显示功能按钮接口
  // 'updateAppMessageShareData', // 分享给朋友
  'onMenuShareAppMessage', // 分享给朋友（老）
  'onMenuShareTimeline' // 分享朋友圈
]

const Wechat = {
  jsApiList: APIS,

  isWeixin () {
    const ua = window.navigator.userAgent.toLowerCase()
    // 如果不在微信浏览器内，微信分享也没意义了对吧？这里判断一下
    if (ua.indexOf('micromessenger') < 0) {
      return false
    } else {
      return true
    }
  },

  // 缓存队列
  cacheQueue (fn) {
    window.times && window.times.push('压入队列cacheQueue:' + (Date.now() - window.startTime))
    console.log('压入队列cacheQueue', fn)
    if (!window.wxQueue) {
      window.wxQueue = []
    }
    window.wxQueue.push(fn)
  },
  // 读取缓存队列
  readQueue () {
    window.times && window.times.push('读取队列wxQueue前:' + (Date.now() - window.startTime))
    // console.log('读取队列wxQueue前')
    // 处理队列
    if (window.wxQueue && window.wxQueue.length > 0) {
      window.wxQueue.map(item => {
        // 如果队列中的是方法，执行之
        if (item instanceof Function) {
          item()
        }
      })
    }
    // 清除队列
    window.wxQueue = null
    window.times && window.times.push('读取队列wxQueue后:' + (Date.now() - window.startTime))
  },

  wxSignature (url, timeline, appmsg) {
    return new Promise((resolve, reject) => {
      wxSignature({ url: url }).then(res => {
        this.initWechat(res.data, timeline, appmsg).then(resp => {
          resolve(resp.data)
        }).catch(err => {
          console.error('initWechat失败', err)
          reject(new Error('initWechat失败'))
        })
      }).catch(err => {
        console.error('获取微信签名失败', err)
        reject(new Error('获取微信签名失败'))
      })
    })
  },

  // 设置微信分享
  initShareMenu (newTitle, newImg, newContent, newLink) {
    // if (!list.length) return
    let title = newTitle
    let img = newImg
    let content = newContent || newLink
    let link = newLink

    // 微信朋友圈分享
    let timeline = {
      title: title,
      link: link,
      imgUrl: img
    }
    // 微信好友分享
    let appmsg = Object.assign({}, timeline, {
      desc: content
    })
    if (this.isWeixin()) {
      this.wxSignature(link, timeline, appmsg)
    }
  },

  // 初始化微信
  initWechat (conf, timeline, appmsg) {
    // let that = this;
    window.times && window.times.push('initWechat前:' + (Date.now() - window.startTime))
    // 仅在微信非正式环境下打开debug
    if (!this.isWeixin()) {
      conf.debug = true
    }
    conf.timestamp = parseInt(conf.timestamp)
    // conf.jsApiList = this.jsApiList
    conf.jsApiList = this.jsApiList
    conf.nonceStr = conf.noncestr

    return new Promise((resolve, reject) => {
      wx.ready(() => {
        window.times && window.times.push('initWechat成功后:' + (Date.now() - window.startTime))
        // 在这里调用 API
        window.isWxReady = true
        this.readQueue()
        this.shareOpen(timeline, appmsg)
        resolve(conf)
      })
      wx.error(res => {
        console.warn('微信config失败：', res)
        window.times && window.times.push('initWechat失败后:' + (Date.now() - window.startTime))
        reject(res)
      })
      wx.config(conf)
    })
  },
  // 启用分享
  shareOpen (shareTimeline, shareAppmsg, success, fail) {
    // console.log('shareOpen ----------> shareTimeline:', shareTimeline, shareAppmsg)

    if (this.isWeixin()) {
      // 微信分享
      if (window.isWxReady) {
        // 微信初始化完成时
        this.shareOpenNow(shareTimeline, shareAppmsg, success, fail)
      } else {
        // 微信初始化未完成时，加到队列中
        this.cacheQueue(() => {
          this.shareOpenNow(shareTimeline, shareAppmsg, success, fail)
        })
      }
    }
  },
  // 分享
  shareOpenNow (shareTimeline, shareAppmsg, success, fail) {
    // 非微信环境跳出
    if (!this.isWeixin()) {
      console.warn('非微信环境，跳过分享')
      return
    }

    // let menuList = [
    //   // 分享到朋友
    //   'menuItem:share:appMessage',
    //   // 分享到朋友圈
    //   'menuItem:share:timeline'
    // ]
    // this.showMenuItems({
    //   menuList: menuList
    // })

    // 过滤微信分享给url加的参数
    // console.info('link: ->', shareTimeline.link)
    // console.info('wx shareOpen ->: ', shareAppmsg)

    // 分享到朋友圈
    this.onMenuShareTimeline(shareTimeline).then(() => {
      // Toast.success('分享朋友圈', 2)
      if (success) success()
    }).catch(() => {
      fail && fail()
    })

    // 分享到好友
    this.onMenuShareAppMessage(shareAppmsg).then(() => {
      // Toast.success('分享好友', 2)
      success && success()
    }).catch(() => {
      fail && fail()
    })
  }
}

// 使用promise重写wx方法
APIS.map((api) => {
  Wechat[api] = (opts) => {
    return new Promise((resolve, reject) => {
      if (!Wechat.isWeixin()) {
        reject(new Error('非微信环境，跳过' + api))
        return
      }
      let defaultOpts = {
        success (res) {
          resolve(res)
        },
        error (err) {
          console.error(err)
          reject(api + ' error') // eslint-disable-line
        },
        cancel (info) {
          console.info(info)
          reject(api + ' cancel')  // eslint-disable-line
        },
        fail (err) {
          console.warn(err)
          reject(api + ' fail') // eslint-disable-line
        },
        complete (info) {
          console.debug(api + ' complete: ', info)
        }
      }
      if (api === 'chooseImage') {
        if (typeof opts.count === 'undefined') {
          console.error('请为%s传入%s！', api, 'count')
          return
        }
        defaultOpts.sizeType = ['original', 'compressed'] // 可以指定是原图还是压缩图，默认二者都有
        defaultOpts.sourceType = ['album', 'camera']
      }
      let options = Object.assign({}, defaultOpts, opts)
      // console.info('wx.' + api, options)
      wx[api](options)
    })
  }
})

export default Wechat
