import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { getSearchResult } from 'api/document'
import Photo from 'components/Photo'
import reactMixin from 'react-mixin'
import { List } from 'antd-mobile'
import Vutils from 'utils/video'
import LoadingMixin from 'mixins/LoadingMixin'
import InfiniteScrollMixin from 'mixins/InfiniteScrollMixin'

const Item = List.Item

class Index extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      pageIndex: 1,
      pageSize: 10,
      query: props.searchData,
      more: true,
      list: []
    }
  }

  componentWillReceiveProps (nextProps) {
    const { searchData, type } = this.props
    if (searchData !== nextProps.searchData || type !== nextProps.type) {
      console.log('333')
      this.setState({ list: [], pageIndex: 1 })
      this.getListData(1, nextProps.searchData, nextProps.type)
    }
  }

  getListData (index, nextQuery, nextType) {
    const { pageIndex, pageSize } = this.state
    const { searchData, type } = this.props
    const { oid } = this.props.match.params
    const params = {
      pageSize: pageSize,
      pageIndex: index || pageIndex,
      baseOrgId: oid,
      infoType: nextType || type,
      query: nextQuery || searchData
    }
    getSearchResult(params).then((res) => {
      let list = [...this.state.list, ...res.data.records]
      this.setState({
        list: list,
        more: list.length < res.data.total,
        pageIndex: this.state.pageIndex + 1,
        loaded: true
      })
    })
  }

  renderDtoImg (data) {
    if (data.infoType === 1) {
      return (
        <div className='flex-row main-center'>
          <div style={{ width: '35%' }} className='text-center'>
            <Photo src={data.firstPage + '?x-oss-process=image/resize,w_300'} ratio={'3b4'} border />
            <div className='p-t-m h5 text-dark'>{data.infoTheme}</div>
          </div>
        </div>
      )
    } else if (data.infoType === 2) {
      return (
        <div className='flex-row cross-start flex1'>
          <div style={{ width: '25vw' }}><Photo src={data.infoUrl + '?x-oss-process=image/resize,w_300'} radius={3} ratio={'4b3'} border /></div>
          <div className='flex1 p-l-m p-t-s'>
            <h3 className='text-overflow'>{data.infoTheme}</h3>
            <div className='text-line-2 h5 text-light p-t-m'>{data.infoDescription}</div>
          </div>
        </div>
      )
    } else if (data.infoType === 3) {
      return (
        <div style={{ width: '100%' }}>
          <Photo
            src={Vutils.isAudio(data.infoUrl) ? '' : data.infoUrl + '?x-oss-process=video/snapshot,t_3000,f_jpg,w_0,h_0'}
            ratio={'16b9'}
            radius={5}
            canPlay
            isAudio={Vutils.isAudio(data.infoUrl)}
          >
            <h3 className='text-white m-h-m m-t-m'>{data.infoTheme}</h3>
          </Photo>
        </div>
      )
    }
  }

  renderSearchList () {
    const { list } = this.state
    const { oid } = this.props.match.params
    if (!list) return null
    const searchList = list.map(item => {
      return (
        <div key={item.uniqueInfoId}>
          <Link to={`/${oid}/search/${item.infoType === 1 ? 'ebooks' : item.infoType === 2 ? 'images' : 'videos'}/${item.uniqueInfoId}`}>
            <Item wrap multipleLine>
              {this.renderDtoImg(item)}
            </Item>
          </Link>
        </div>
      )
    })
    return <List>{searchList}</List>
  }

  render () {
    return (
      <div ref='scrollcontainer'>
        {this.renderSearchList()}
        { this.renderListPreload() }
        { this.renderNoResult('没有相关数据') }
      </div>
    )
  }
}

Index.propTypes = {
  searchData: PropTypes.string
}

Index.defaultProps = {
  searchData: ''
}

reactMixin(Index.prototype, InfiniteScrollMixin)
reactMixin(Index.prototype, LoadingMixin)

export default withRouter(Index)
