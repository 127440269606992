import React from 'react'
import { Carousel, Toast } from 'antd-mobile'
import styles from './styles.less'
import { getDocumentDetail } from 'api/document'
import Wechat from 'utils/weixin'

class EbookDto extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      data: null,
      pageIndex: 1,
      total: 3,
      frontUrl: ''
    }
  }

  componentDidMount () {
    this.getEbookDetail()
  }

  getEbookDetail () {
    const { id } = this.props.match.params
    getDocumentDetail({ uniqueInfoId: id }).then((res) => {
      this.setState({
        data: res.data,
        total: res.data ? res.data.pageNum : 0,
        frontUrl: res.data && res.data.firstPage ? res.data.firstPage.split('1.jpeg')[0] : ''
      })
      this.weChatShare(res.data)
    })
  }

  // 设置微信分享
  weChatShare (data) {
    const { pathname, search } = this.props.location
    let title = data.infoTheme
    let img = `${data.firstPage}?x-oss-process=image/resize,w_100`
    let content = data.infoDescription
    let link = `http://m.chntong.net${pathname}${search}`
    Wechat.initShareMenu(title, img, content, link)
  }

  goNext () {
    const { pageIndex, total } = this.state
    if (pageIndex + 2 > total) {
      console.log('这是最后一页!!!')
      Toast.info('这是最后一页 !!!', 1)
    }
    if (pageIndex + 1 > total) {
      return
    }
    this.refs.current.className = `${styles.current_page} ${styles.current_next}`
    setTimeout(() => {
      // console.log('goNext')
      this.refs.current.className = styles.current_page
      this.refs.next.className = ''
      this.setState({
        pageIndex: pageIndex + 1
      })
    }, 300)
  }

  goPre () {
    const { pageIndex } = this.state
    if (pageIndex < 3) {
      console.log('这是第一页!!!')
      Toast.info('这是第一页 !!!', 1)
    }
    if (pageIndex === 1) {
      return
    }
    this.refs.pre.className = `${styles.pre_page} ${styles.current_pre}`
    setTimeout(() => {
      this.refs.pre.className = ''
      this.refs.next.className = ''
      this.setState({
        pageIndex: pageIndex - 1
      })
    }, 300)
  }

  changeType (from, to) {
    if ((from === 0 && to === 2) || (from === 1 && to === 0) || (from === 2 && to === 1)) {
      this.goPre()
    } else if ((from === 0 && to === 1) || (from === 1 && to === 2) || (from === 2 && to === 0) || from === to) {
      this.goNext()
    }
  }

  renderPage () {
    const { pageIndex, frontUrl, data } = this.state
    if (!data) return null
    let currentPage = `${frontUrl}${pageIndex}.jpeg`
    let prePage = `${frontUrl}${pageIndex - 1}.jpeg`
    let nextPage = `${frontUrl}${pageIndex + 1}.jpeg`
    return (
      data.firstPage
        ? <div className='block-rel' style={{ width: '100vw' }}>
          {pageIndex === 1 ? null : <img ref='pre' src={prePage} alt='' />}
          <img ref='current' src={currentPage} alt='' className={styles.current_page} />
          <img ref='next' src={nextPage} alt='' />
        </div>
        : <div className='text-white text-center flex1'>
          <h2 className='p-b-m'>《{data.infoTheme}》</h2>
          <h2>电子书制作中...</h2>
        </div>
    )
  }

  render () {
    return (
      <div className={`${styles.ebook_dto} flex-row cross-center height-exclude-bar bg-darker`}>
        {this.renderPage()}
        <div style={{ position: 'absolute', zIndex: 99999, left: 0, top: 0, width: '100vw' }}>
          <Carousel
            autoplay={false}
            infinite
            dots={false}
            beforeChange={(from, to) => this.changeType(from, to)}
          >
            <div className='height-exclude-bar' />
            <div className='height-exclude-bar' />
            <div className='height-exclude-bar' />
          </Carousel>
        </div>
      </div>
    )
  }
}

export default EbookDto
