import React from 'react'
import Vutils from 'utils/video'
import { Link } from 'react-router-dom'
import musicBG from 'images/heijiao.gif'
import musicBG1 from 'images/heijiao.jpg'
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'
import Wechat from 'utils/weixin'

class TopicItemCarousel extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentWillMount () {
    const { list, current } = this.props.location.state
    this.setState({
      current: current,
      list: list
    })
  }

  componentDidMount () {
    this.initSwiper()
  }

  initSwiper () {
    // 等dom接受到数据再初始化
    // eslint-disable-next-line no-unused-vars
    var mySwiper = new Swiper('.swiper-container', {
      initialSlide: this.state.current || 0,
      slidesPerView: 1,
      loop: true,
      autoHeight: true, // 高度随内容变化
      observer: true,
      observeParents: true,
      on: {
        slideChangeTransitionStart: function () {
          // 切换时回到顶端
          document.body.scrollTop = document.documentElement.scrollTop = 0
        }
      }
    })
  }

  previewImage (url) {
    if (Wechat.isWeixin()) {
      Wechat.previewImage({
        current: url,
        urls: [url]
      })
    }
  }

  renderItems () {
    const { list } = this.state
    const { oid } = this.props.match.params
    const itemList = list.map((item, index) => {
      let differentArea
      if (item.infoType === 2) {
        differentArea = <div className='text-center p-v-l'>
          <img src={item.infoUrl + '?x-oss-process=image/resize,w_750'} alt='' style={{ width: '85vw' }} onClick={this.previewImage.bind(this, item.infoUrl + '?x-oss-process=image/resize,w_750')} />
        </div>
      } else if (item.infoType === 3) {
        differentArea = <div className='text-center p-t-50 bg-darker'>
          <video
            onPause={() => this.setState({ musicBG: musicBG1 })}
            onPlay={() => this.setState({ musicBG: musicBG })}
            poster={Vutils.isAudio(item.infoUrl) ? this.state.musicBG : null}
            src={item.infoUrl}
            controls='controls'
            // autoPlay='autoplay'
            style={{ width: '100%' }}
          />
        </div>
      } else {
        differentArea = <div className='text-center p-v-l'>
          <Link to={`/${oid}/home/ebooks/${item.uniqueInfoId}`}>
            <img src={item.firstPage + '?x-oss-process=image/resize,w_750'} alt='' style={{ width: '85vw' }} />
          </Link>
        </div>
      }
      return (
        <div className='swiper-slide' key={item.uniqueInfoId} style={{ minHeight: 'calc(100vh - 50px)' }}>
          {differentArea}
          <div className='p-b-l text-dark text-center text-bold h4'>{`${index + 1} / ${list.length}`}</div>
          <div style={{ height: 1, width: '100vw', background: '#ccc' }} />
          <div className='p-l'>
            <div className='h3 text-center text-darker'>{item.infoTheme}</div>
            {item.infoDescription
              ? <h4 className='p-v-l text-justify' style={{ wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: item.infoDescription.replace(/\n/ig, '<br />') }} />
              : null}
          </div>
        </div>
      )
    })
    return itemList
  }

  render () {
    return (
      <div className='swiper-container'>
        <div className='swiper-wrapper flex-row'>
          {this.renderItems()}
        </div>
        <div className='swiper-pagination' />
      </div>
    )
  }
}

export default TopicItemCarousel
