exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".search___2UArC [class*='am-list-item'] img {\n  height: 35.6%;\n}\n.search___2UArC .SearchBar___1nViN {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  z-index: 88888;\n}\n.search___2UArC .MidSearchbar___269P8 {\n  padding-top: 250px;\n  margin: 0 40px;\n}\n", ""]);

// exports
exports.locals = {
	"search": "search___2UArC",
	"SearchBar": "SearchBar___1nViN",
	"MidSearchbar": "MidSearchbar___269P8"
};