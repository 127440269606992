import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { getDocumentList } from 'api/document'
import Photo from 'components/Photo'
import Vutils from 'utils/video'

class VideoArea extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    this.getVideoList()
  }

  // 资料类型：1 电子书，2 图片,3 音视频
  getVideoList () {
    const { oid } = this.props.match.params
    const params = {
      pageSize: 3,
      pageIndex: 1,
      baseOrgId: oid,
      infoType: 3
    }
    getDocumentList(params).then((res) => {
      this.setState({
        videoList: res.data.records
      })
    })
  }

  renderVideoArea () {
    const { videoList } = this.state
    const { oid } = this.props.match.params
    if (!videoList) return null
    const area = videoList.map(item => {
      return (
        <div key={item.baseId} style={{ width: '100%' }} className='p-h-m p-b-m'>
          <Link to={`/${oid}/home/videos/${item.uniqueInfoId}`}>
            <Photo
              src={Vutils.isAudio(item.infoUrl) ? '' : item.infoUrl + '?x-oss-process=video/snapshot,t_3000,f_jpg,w_0,h_0'}
              ratio={'16b9'}
              radius={5}
              canPlay
              isAudio={Vutils.isAudio(item.infoUrl)}
            >
              <h3 className='text-white m-h-m m-t-m'>{item.infoTheme}</h3>
            </Photo>
          </Link>
        </div>
      )
    })
    return area
  }

  render () {
    return (
      <div className='bg-white p-t-m'>
        {this.renderVideoArea()}
      </div>
    )
  }
}

export default withRouter(VideoArea)
