import React from 'react'
import Intro from './intro'
import Ebooks from './ebooks'
import EbookDto from 'components/ThemeDto/EbookDto'
import Images from './images'
import ImagesDto from 'components/ThemeDto/ImageDto'
import Videos from './videos'
import VideoDto from 'components/ThemeDto/VideoDto'
import Main from './main'
import { Route, Switch, Redirect } from 'react-router-dom'
import MainTabBar from 'components/MainTabBar'

class Index extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    return (
      <div className='p-b-50'>
        <Switch>
          <Route path='/:oid/home' exact component={Main} />
          <Route path='/:oid/home/intro' component={Intro} />
          <Route path='/:oid/home/images' exact component={Images} />
          <Route path='/:oid/home/images/:id' component={ImagesDto} />
          <Route path='/:oid/home/ebooks' exact component={Ebooks} />
          <Route path='/:oid/home/ebooks/:id' component={EbookDto} />
          <Route path='/:oid/home/videos' exact component={Videos} />
          <Route path='/:oid/home/videos/:id' key={new Date().getTime()} component={VideoDto} />
          <Redirect to='/:oid/home' />
        </Switch>
        <MainTabBar {...this.props} />
      </div>
    )
  }
}

export default Index
