import axios from 'axios'
import { Toast } from 'antd-mobile'
// import qs from 'querystring'

// 创建请求实例 ==============================
const service = axios.create({
  baseURL: '/api',
  timeout: 5000 // 请求超时时间
})

// 注入 request =========================================
service.interceptors.request.use(req => {
  // console.log(url, 'axios request，头', req.headers)
  // console.log(url, 'axios request，入参', req.data)
  // console.log('axios fetch: ', req.url, req.data, req.headers.post.Cookie)
  // console.log('请求 ---------> ', req)
  return req
}, err => Promise.reject(err))

// 注入 response =========================================
service.interceptors.response.use(resp => {
  // console.log('响应 ---------> ', resp.data)
  const { data, request } = resp
  const { responseURL } = request

  // const sArr = responseURL.split('/')
  // const api = sArr[sArr.length - 1]
  const api = responseURL
  // console.log('browser 响应 ---------> ', api, resp)
  // console.log('responseURL ---------> ', resp.request.responseURL)
  if (resp && resp.status === 200) {
    if (!data) {
      Toast.fail(`${api}: 响应中不存在预期的data!`)
      return null
    }
    if (data.code !== 200) {
      Toast.fail(`请求出错拉！${data.msg || data.Msg}`, 5)
    }
    return data
  } else {
    Toast.fail(`${api} statusText: ${resp.statusText}`)
    return null
  }
}, err => Promise.reject(err)
)

// const instance = {
//   fetch: (url, method, params) => {
//     // 创建请求实例 ==============================
//     const query = axios.create({
//       baseURL: '/api'
// })

//     // 注入 request =========================================
//     query.interceptors.request.use(req => {
//       // console.log(url, 'axios request，头', req.headers)
//       // console.log(url, 'axios request，入参', req.data)
//       // console.log('axios fetch: ', req.url, req.data, req.headers.post.Cookie)
//       // console.log('请求 ---------> ', req)
//       return req
//     }, err => Promise.reject(err))

//     // 注入 response =========================================
//     query.interceptors.response.use(resp => {
//       // console.log('响应 ---------> ', resp.data)
//       const { data, request } = resp
//       const { responseURL } = request

//       // const sArr = responseURL.split('/')
//       // const api = sArr[sArr.length - 1]
//       const api = responseURL
//       // console.log('browser 响应 ---------> ', api, resp)
//       // console.log('responseURL ---------> ', resp.request.responseURL)
//       if (resp && resp.status === 200) {
//         if (!data) {
//           Toast.fail(`${api}: 响应中不存在预期的data!`)
//           return null
//         }
//         if (data.code !== 200) {
//           Toast.fail(`请求出错拉！${data.msg || data.Msg}`, 5)
//         }
//         return data
//       } else {
//         Toast.fail(`${api} statusText: ${resp.statusText}`)
//         return null
//       }
//     }, err => Promise.reject(err))
//     if (method === 'post') {
//       return query.post(url, params)
//     } else {
//       return query.get(url, { params: params })
//     }
//   }
// }

export default service
