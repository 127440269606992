import React from 'react'
import Photo from 'components/Photo'
import { Tabs, List } from 'antd-mobile'
import { StickyContainer, Sticky } from 'react-sticky'
import { getOrgDetail, getOrgLeaderList, getOrgLeaderSpeech } from 'api/document'
import Record from './Record'

const tabs = [
  { title: '机构简介' },
  { title: '领导介绍' },
  { title: '领导致辞' },
  { title: '视频留言' }
]

class Intro extends React.Component {
  constructor (props) {
    super(props)
    this.renderSticky = this.renderSticky.bind(this)
    this.state = {}
  }

  componentDidMount () {
    this.getOrgDto()
    this.getOrgLeaderList()
  }

  getOrgDto () {
    const { oid } = this.props.match.params
    getOrgDetail({ id: oid }).then((res) => {
      this.setState({
        orgData: res.data
      })
    })
  }

  getOrgLeaderList () {
    const { oid } = this.props.match.params
    getOrgLeaderList({ baseOrgId: oid }).then((res) => {
      this.setState({
        leaderList: res.data
      })
      getOrgLeaderSpeech({ leaderIntroduceId: res.data[0].leaderIntroduceId }).then((res) => {
        this.setState({
          leaderSpeech: res.data
        })
      })
    })
  }

  renderOrgDto () {
    const { orgData } = this.state
    if (!orgData) return null
    return (
      <div>
        {/* <img src={orgData.logoUrl} alt='' style={{ width: '100%' }} /> */}
        {orgData.description
          ? <div className='h4 text-justify text-indent-2em p-t-l text-height-15' dangerouslySetInnerHTML={{ __html: orgData.description.replace(/\n/ig, '<br /><span style=width:2em;display:inline-block></span>') }} />
          : null}
      </div>
    )
  }

  renderOrgLeaderList () {
    const { leaderList } = this.state
    if (!leaderList) return null
    const list = leaderList.map(item => {
      return (
        <List.Item key={item.leaderIntroduceId}>
          <div className='flex-row p-v-m h4 text-light' style={{ whiteSpace: 'normal' }}>
            <div style={{ width: '15vw' }}>
              <Photo src={item.leaderPhotosUrl + '?x-oss-process=image/resize,w_100'} ratio='1b1' radius='50%' />
            </div>
            <div className='flex1 p-l-m'>
              <div className='h3 text-bold text-sienna'>{item.leaderName}</div>
              <div className='p-v-s text-justify'>职务：{item.leaderTitle}</div>
              <div>{item.introduceContent}</div>
            </div>
          </div>
        </List.Item>
      )
    })
    return (
      <List>{list}</List>
    )
  }

  renderLeaderSpeech () {
    const { leaderSpeech, orgData } = this.state
    if (!leaderSpeech || !orgData) return null
    return (
      <React.Fragment>
        <div className='flex1 flex-column h4'>
          <div className='p-v-l'>各位朋友：</div>
          {leaderSpeech.speechContent
            ? <div className='text-justify text-indent-2em' style={{ lineHeight: '25px' }} dangerouslySetInnerHTML={{ __html: leaderSpeech.speechContent.replace(/\n/ig, '<br /><span style=width:2em;display:inline-block></span>') }} />
            : null}
          <div className='p-v-xl' style={{ width: '100%', textAlign: 'right' }}>
            <span className='p-r-l'>{leaderSpeech.leaderTitle}</span>{leaderSpeech.leaderName}
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderSticky (props) {
    return (
      <Sticky>
        {({ style }) => <div style={{ ...style, zIndex: 1 }}><Tabs.DefaultTabBar {...props} /></div>}
      </Sticky>)
  }

  render () {
    return (
      <StickyContainer>
        <Tabs tabs={tabs}
          tabBarTextStyle={{ fontWeight: 'bold' }}
          tabBarActiveTextColor='#896027'
          swipeable={false}
          prerenderingSiblingsNumber={0}
          tabBarUnderlineStyle={{ border: '1px solid #896027' }}
          renderTabBar={this.renderSticky}
        >
          <div className='p-l'>{this.renderOrgDto()}</div>
          <div>{this.renderOrgLeaderList()}</div>
          <div className='p-l'>{this.renderLeaderSpeech()}</div>
          <Record />
        </Tabs>
      </StickyContainer>
    )
  }
}

export default Intro
