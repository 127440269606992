import React from 'react'
import { SearchBar, Tabs } from 'antd-mobile'
import styles from './styles.less'
import qs from 'query-string'
import SearchResult from './searchResult'
import { getSearchResult } from 'api/document'

class Main extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      imageCount: 0,
      ebookCount: 0,
      mediaCount: 0
    }
  }

  componentWillMount () {
    const searchKey = qs.parse(this.props.location.search).key
    if (searchKey) {
      this.getListCount(2, searchKey)
      this.getListCount(1, searchKey)
      this.getListCount(3, searchKey)
    }
  }

  componentDidMount () {
    this.autoFocusInst.focus()
  }

  getListCount (type, key) {
    const { oid } = this.props.match.params
    const searchKey = qs.parse(this.props.location.search).key
    getSearchResult({ infoType: type, baseOrgId: oid, query: key }).then((res) => {
      let cont = res.data.total
      if (type === 2) {
        this.setState({ imageCount: cont })
      } else if (type === 1) {
        this.setState({ ebookCount: cont })
      } else if (type === 3) {
        this.setState({ mediaCount: cont })
      }
    })
  }

  renderSearchData (v) {
    this.props.history.replace({ pathname: this.props.location.pathname, search: v ? `key=${v}` : '' })
    this.getListCount(2, v)
    this.getListCount(1, v)
    this.getListCount(3, v)
  }

  renderTabs () {
    const { history } = this.props
    const { imageCount, ebookCount, mediaCount } = this.state
    const { oid } = this.props.match.params
    const searchKey = qs.parse(this.props.location.search).key
    const searchType = this.props.match.params.type
    const tabs = [
      { title: `图片(${imageCount})`, type: 2 },
      { title: `电子书(${ebookCount})`, type: 1 },
      { title: `音视频(${mediaCount})`, type: 3 }
    ]
    let initTab
    if (searchType === '1') {
      initTab = 1
    } else if (searchType === '3') {
      initTab = 2
    } else {
      initTab = 0
    }
    return (
      <div>
        <Tabs tabs={tabs}
          initialPage={initTab}
          onTabClick={(tab) => history.replace({ pathname: `/${oid}/search/${tab.type}`, search: `key=${searchKey}` })}
        />
      </div>
    )
  }

  render () {
    const searchKey = qs.parse(this.props.location.search).key
    const searchType = this.props.match.params.type
    return (
      <div className={styles.search}>
        <div className={searchKey ? styles.SearchBar : styles.MidSearchbar}>
          <SearchBar
            placeholder='自动获取光标'
            ref={ref => (this.autoFocusInst = ref)}
            defaultValue={searchKey || ''}
            cancelText='搜索'
            showCancelButton
            onCancel={(v) => this.renderSearchData(v)}
            onSubmit={(v) => this.renderSearchData(v)}
          />
          {searchKey ? this.renderTabs() : <div className='p-h-l h4 text-dark m-t-xxl text-center'>请输入搜索关键词</div>}
        </div>
        {/* {searchKey ? <div className='p-h-l h4 text-dark p-t-50 m-t-m p-b-l'>"{searchKey}"的相关资料</div> : null} */}
        {searchKey
          ? <div style={{ paddingTop: 88 }}>
            <SearchResult searchData={searchKey} type={searchType} />
          </div>
          : null}
      </div>
    )
  }
}

export default Main
