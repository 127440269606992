import request from 'utils/axios'

// 微信签名
export function wxSignature (params) {
  return request({
    url: '/wx/signature',
    method: 'post',
    params
  })
}

// 领导列表
export function getOrgLeaderList (params) {
  return request({
    url: '/front/leader/list',
    method: 'get',
    params
  })
}

// 领导致辞
export function getOrgLeaderSpeech (params) {
  return request({
    url: '/front/leaderSpeech/detail',
    method: 'get',
    params
  })
}

// 机构详情
export function getOrgDetail (params) {
  return request({
    url: '/front/org/detail',
    method: 'get',
    params
  })
}

// 普通展示列表
export function getDocumentList (params) {
  return request({
    url: '/front/doc/list',
    method: 'get',
    params
  })
}

// 单个详情页
export function getDocumentDetail (params) {
  return request({
    url: '/front/doc/detail',
    method: 'get',
    params
  })
}

// 专题列表
export function getTopicList (params) {
  return request({
    url: '/front/topic/list',
    method: 'get',
    params
  })
}

// 专题详情
export function getTopicDetail (params) {
  return request({
    url: '/front/topic/detail',
    method: 'get',
    params
  })
}

// 搜索
export function getSearchResult (params) {
  return request({
    url: '/front/doc/search',
    method: 'get',
    params
  })
}

export function getPolicy (params) {
  return request({
    url: '/document/policy',
    method: 'get',
    params
  })
}

// 上传视频留言
export function uploadMedia (params) {
  return request({
    url: '/v2/front/upload',
    method: 'post',
    data: params
  })
}

// 视频留言列表
export function publishVideoMessageList (params) {
  return request({
    url: '/v2/front/publishVideoMessageList',
    method: 'post',
    params
  })
}
