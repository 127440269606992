import React from 'react'
import { Link } from 'react-router-dom'
import { getDocumentList } from 'api/document'
import Photo from 'components/Photo'
import reactMixin from 'react-mixin'
import LoadingMixin from 'mixins/LoadingMixin'
import InfiniteScrollMixin from 'mixins/InfiniteScrollMixin'

class Index extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      pageIndex: 1,
      pageSize: 10,
      more: true,
      list: []
    }
  }

  // 资料类型：1 电子书，2 图片,3 音视频
  getListData () {
    const { pageIndex, pageSize } = this.state
    const { oid } = this.props.match.params
    const params = {
      pageSize: pageSize,
      pageIndex: pageIndex,
      baseOrgId: oid,
      infoType: 1
    }
    getDocumentList(params).then((res) => {
      let list = [...this.state.list, ...res.data.records]
      this.setState({
        list: list,
        more: list.length < res.data.total,
        pageIndex: this.state.pageIndex + 1,
        loaded: true
      })
    })
  }

  renderEbookList () {
    const { list } = this.state
    const { oid } = this.props.match.params
    if (!list) return null
    const ebookList = list.map(item => {
      return (
        <div key={item.uniqueInfoId} style={{ width: '50%' }} className='p-m'>
          <Link to={`/${oid}/home/ebooks/${item.uniqueInfoId}`}>
            <Photo src={item.firstPage + '?x-oss-process=image/resize,w_300'} ratio={'2b3'} border />
          </Link>
        </div>
      )
    })
    return <div className='flex-row flex-wrap p-m bg-white' style={{ width: '100vw' }}>{ebookList}</div>
  }

  render () {
    return (
      <div ref='scrollcontainer'>
        {this.renderEbookList()}
        { this.renderListPreload() }
        { this.renderNoResult('没有相关数据') }
      </div>
    )
  }
}

reactMixin(Index.prototype, InfiniteScrollMixin)
reactMixin(Index.prototype, LoadingMixin)

export default Index
