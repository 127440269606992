import React from 'react'
import { Link } from 'react-router-dom'
import { getTopicList } from 'api/document'
import Photo from 'components/Photo'
import { List } from 'antd-mobile'
import reactMixin from 'react-mixin'
import LoadingMixin from 'mixins/LoadingMixin'
import InfiniteScrollMixin from 'mixins/InfiniteScrollMixin'
import Wechat from 'utils/weixin'

const Item = List.Item

class Main extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      pageIndex: 1,
      pageSize: 10,
      more: true,
      list: []
    }
  }

  componentDidMount () {
    this.getFirstTopicToShare()
  }

  getFirstTopicToShare () {
    const { oid } = this.props.match.params
    const params = {
      pageSize: 1,
      pageIndex: 1,
      baseOrgId: oid
    }
    getTopicList(params).then((res) => {
      this.weChatShare(res.data.records[0])
    })
  }

  getListData () {
    const { pageIndex, pageSize } = this.state
    const { oid } = this.props.match.params
    const params = {
      pageSize: pageSize,
      pageIndex: pageIndex,
      baseOrgId: oid
    }
    getTopicList(params).then((res) => {
      let list = [...this.state.list, ...res.data.records]
      this.setState({
        list: list,
        more: list.length < res.data.total,
        pageIndex: this.state.pageIndex + 1,
        loaded: true
      })
    })
  }

  // 设置微信分享
  weChatShare (data) {
    const { pathname, search } = this.props.location
    let title = data.topicName
    let img = `${data.topicImage}?x-oss-process=image/resize,w_100`
    let content = data.topicDesc
    let link = `http://m.chntong.net${pathname}${search}`
    Wechat.initShareMenu(title, img, content, link)
  }

  renderImageList () {
    const { list } = this.state
    const { oid } = this.props.match.params
    if (!list) return null
    const imageList = list.map(item => {
      return (
        <div key={item.topicId}>
          <Link to={`/${oid}/topic/detail/${item.topicId}`}>
            <Item wrap multipleLine>
              <div className='flex-row cross-start flex1'>
                <div style={{ width: '25vw' }}><Photo src={item.topicImage + '?x-oss-process=image/resize,w_300'} ratio='4b3' radius={3} border /></div>
                <div className='flex1 p-l-m p-t-s'>
                  <h3>{item.topicName}</h3>
                  <div className='text-line-2 h5 text-light p-t-m'>{item.topicDesc}</div>
                </div>
              </div>
            </Item>
          </Link>
        </div>
      )
    })
    return <List>{imageList}</List>
  }
  render () {
    return (
      <div ref='scrollcontainer' className='p-b-50'>
        {this.renderImageList()}
        { this.renderListPreload() }
        { this.renderNoResult('没有相关数据') }
      </div>
    )
  }
}

reactMixin(Main.prototype, InfiniteScrollMixin)
reactMixin(Main.prototype, LoadingMixin)

export default Main
