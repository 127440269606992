import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { getDocumentList } from 'api/document'
import Photo from 'components/Photo'

class EbookArea extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    this.getEbookList()
  }

  // 资料类型：1 电子书，2 图片,3 音视频
  getEbookList () {
    const { oid } = this.props.match.params
    const params = {
      pageSize: 3,
      pageIndex: 1,
      baseOrgId: oid,
      infoType: 1
    }
    getDocumentList(params).then((res) => {
      this.setState({
        imageList: res.data.records
      })
    })
  }

  renderEbookArea () {
    const { imageList } = this.state
    const { oid } = this.props.match.params
    if (!imageList) return null
    const area = imageList.map(item => {
      return (
        <div key={item.baseId} style={{ width: '33.33%' }} className='p-s'>
          <Link to={`/${oid}/home/ebooks/${item.uniqueInfoId}`}>
            <Photo src={item.firstPage + '?x-oss-process=image/resize,w_300'} ratio={'2b3'} border />
          </Link>
        </div>
      )
    })
    return area
  }

  render () {
    return (
      <div className='flex-row p-s bg-white'>
        {this.renderEbookArea()}
      </div>
    )
  }
}

export default withRouter(EbookArea)
