import React from 'react'
import { getDocumentDetail } from 'api/document'
import Wechat from 'utils/weixin'

class ImageDto extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    this.getImageDetail()
  }

  getImageDetail () {
    const { id } = this.props.match.params
    getDocumentDetail({ uniqueInfoId: id }).then((res) => {
      this.setState({
        data: res.data
      })
      this.weChatShare(res.data)
    })
  }

  // 设置微信分享
  weChatShare (data) {
    const { pathname, search } = this.props.location
    let title = data.infoTheme
    let img = `${data.infoUrl}?x-oss-process=image/resize,w_100`
    let content = data.infoDescription
    let link = `http://m.chntong.net${pathname}${search}`
    Wechat.initShareMenu(title, img, content, link)
  }

  previewImage (url) {
    if (Wechat.isWeixin()) {
      Wechat.previewImage({
        current: url,
        urls: [url]
      })
    }
  }

  render () {
    const { data } = this.state
    if (!data) return null
    return (
      <div className='bg-white'>
        <div className='text-center p-v-l' onClick={this.previewImage.bind(this, data.infoUrl + '?x-oss-process=image/resize,w_750')}>
          <img src={data.infoUrl + '?x-oss-process=image/resize,w_750'} alt='' style={{ width: '85vw', border: '1px solid #ccc' }} />
        </div>
        <div style={{ height: 1, width: '100vw', background: '#ccc' }} />
        <div className='p-l'>
          <h2 className='text-center text-darker'>{data.infoTheme}</h2>
          {data.infoDescription
            ? <h4 className='p-v-l text-justify' style={{ wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: data.infoDescription.replace(/\n/ig, '<br />') }} />
            : null}
        </div>
      </div>
    )
  }
}

export default ImageDto
