import React from 'react'
import { getDocumentDetail, getDocumentList } from 'api/document'
import Vutils from 'utils/video'
import musicBG from 'images/heijiao.gif'
import musicBG1 from 'images/heijiao.jpg'
import Wechat from 'utils/weixin'
import { Toast } from 'antd-mobile'

class videoDto extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      musicBG: musicBG1
    }
    this.nextVideo = this.nextVideo.bind(this)
  }

  componentDidMount () {
    this.getVideoList()
    getDocumentDetail({ uniqueInfoId: this.props.match.params.id }).then((res) => {
      this.setState({
        data: res.data
      })
      this.weChatShare(res.data)
    })
  }

  // 资料类型：1 电子书，2 图片,3 音视频
  getVideoList () {
    const { oid } = this.props.match.params
    const params = {
      pageSize: 100,
      pageIndex: 1,
      baseOrgId: oid,
      infoType: 3
    }
    getDocumentList(params).then((res) => {
      this.setState({
        videoList: res.data.records
      })
    })
  }

  nextVideo (type) { // 1-正常 2-异常
    const { oid } = this.props.match.params
    const videoList = this.state.videoList.map(item => item.uniqueInfoId)
    const currentVideo = this.state.data.uniqueInfoId
    const currentIndex = videoList.indexOf(currentVideo)
    Toast.loading(type === 2 ? '视频异常，播放下一条' : '即将播放下一条', 1)
    setTimeout(() => {
      if (currentIndex === videoList.length - 1) {
        this.props.history.replace(`/${oid}/home/videos/${videoList[0]}`)
      } else {
        this.props.history.replace(`/${oid}/home/videos/${videoList[currentIndex + 1]}`)
      }
    }, 1000)
  }

  // 设置微信分享
  weChatShare (data) {
    const { pathname, search } = this.props.location
    let title = data.infoTheme
    let img = Vutils.isAudio(data.infoUrl) ? musicBG1 : data.infoUrl + '?x-oss-process=video/snapshot,t_3000,f_jpg,w_0,h_0'
    let content = data.infoDescription
    let link = `http://m.chntong.net${pathname}${search}`
    Wechat.initShareMenu(title, img, content, link)
  }

  render () {
    const { data } = this.state
    if (!data) return null
    return (
      <div>
        <div className='text-center p-t-50 bg-darker'>
          <video
            // 以下三个是微信的设置
            x5-playsinline='true'
            webkit-playsinline='true'
            playsInline={true}
            onPause={() => this.setState({ musicBG: musicBG1 })}
            onPlay={() => this.setState({ musicBG: musicBG })}
            poster={Vutils.isAudio(data.infoUrl) ? this.state.musicBG : null}
            src={data.infoUrl}
            onEnded={this.nextVideo.bind(this, 1)}
            onError={this.nextVideo.bind(this, 2)}
            controls='controls'
            autoPlay='autoplay'
            style={{ width: '100%' }}
          />
        </div>
        {/* <div style={{ height: 1, width: '100vw', background: '#ccc' }} /> */}
        <div className='p-l'>
          <h2 className='text-darker text-bolder p-t-l'>{data.infoTheme}</h2>
          {data.infoDescription
            ? <div className='text-dark p-v-l h3 text-justify' style={{ wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: data.infoDescription.replace(/\n/ig, '<br />') }} />
            : null}
        </div>
      </div>
    )
  }
}

export default videoDto
