import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { getDocumentList } from 'api/document'
import Photo from 'components/Photo'

class ImageArea extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    this.getImageList()
  }

  // 资料类型：1 电子书，2 图片,3 音视频
  getImageList () {
    const { oid } = this.props.match.params
    const params = {
      pageSize: 9,
      pageIndex: 1,
      baseOrgId: oid,
      infoType: 2
    }
    getDocumentList(params).then((res) => {
      this.setState({
        imageList: res.data.records
      })
    })
  }

  renderImageArea () {
    const { imageList } = this.state
    const { oid } = this.props.match.params
    if (!imageList) return null
    const area = imageList.map(item => {
      return (
        <div key={item.uniqueInfoId} style={{ width: '33.33%' }} className='p-s'>
          <Link to={`/${oid}/home/images/${item.uniqueInfoId}`}>
            <Photo src={item.infoUrl + '?x-oss-process=image/resize,w_300'} radius={3} border />
            <div className='text-dark text-center p-s text-overflow'>{item.infoTheme}</div>
          </Link>
        </div>
      )
    })
    return area
  }

  render () {
    return (
      <div className='flex-row flex-wrap p-s bg-white'>
        {this.renderImageArea()}
      </div>
    )
  }
}

export default withRouter(ImageArea)
