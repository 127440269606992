import React, { Component } from 'react'
import Home from './home/index'
import Topic from './topic'
import Search from './search'
import { getOrgDetail } from 'api/document'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    this.getOrgDto()
  }

  getOrgDto () {
    const oid = this.props.location.pathname.split('/')[1]
    getOrgDetail({ id: oid }).then((res) => {
      document.title = res.data.name
    })
  }

  render () {
    return (
      <div className='App height-exclude-bar'>
        <Switch>
          <Route path='/:oid/home' component={Home} />
          <Route path='/:oid/topic' component={Topic} />
          <Route path='/:oid/search' component={Search} />
          <Redirect to='/:oid/home' />
        </Switch>
      </div>
    )
  }
}

export default withRouter(App)
