exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Photo_component___2ubQd {\n  position: relative;\n  background-color: #111;\n}\n.Photo_component___2ubQd .img___1j9sy {\n  position: absolute;\n  opacity: 0;\n  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease;\n  transition: opacity 0.3s ease, -webkit-transform 0.3s ease;\n  transition: opacity 0.3s ease, transform 0.3s ease;\n  transition: opacity 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;\n}\n.Photo_component___2ubQd .img___1j9sy[class*='fadeIn'] {\n  opacity: 1;\n}\n.Photo_component___2ubQd .inner___3Paw3 {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n.Photo_component___2ubQd .player___jdBVx {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  width: 20%;\n  margin-left: -10%;\n  margin-top: -10%;\n}\n", ""]);

// exports
exports.locals = {
	"Photo_component": "Photo_component___2ubQd",
	"img": "img___1j9sy",
	"inner": "inner___3Paw3",
	"player": "player___jdBVx"
};