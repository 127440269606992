exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".modal_style___1Hl7L [class*='am-button-primary'] {\n  border: none;\n}\n", ""]);

// exports
exports.locals = {
	"modal_style": "modal_style___1Hl7L"
};