import React, { Component } from 'react'
import { TabBar } from 'antd-mobile'
import { getOrgDetail } from 'api/document'

class MainTabBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedTab: `/${props.match.params.oid}/home`,
      fullScreen: true,
      orgDetail: null
    }
  }

  componentWillMount () {
    this.setState({
      selectedTab: this.props.location.pathname
    })
  }

  componentDidMount () {
    this.getOrgDto()
  }

  componentWillReceiveProps (nextprops) {
    const { pathname } = this.props.location
    if (pathname !== nextprops.location.pathname) {
      this.setState({
        selectedTab: nextprops.location.pathname
      })
    }
  }

  getOrgDto () {
    const oid = this.props.location.pathname.split('/')[1]
    getOrgDetail({ id: oid }).then((res) => {
      this.setState({
        orgDetail: res.data
      })
    })
  }

  renderVr () {
    if (!this.state.orgDetail || !this.state.orgDetail.vrLink) return
    return (
      <TabBar.Item
        title={this.state.orgDetail.vrText}
        icon={
          <div
            style={{
              width: '40px',
              height: '22px',
              background: `url(${require('../images/VR.png')}) center center /  22px 22px no-repeat`
            }}
          />
        }
        // badge={3}
        onPress={() => {
          window.location.href = this.state.orgDetail.vrLink
        }}
        data-seed='logId'
      />)
  }

  render () {
    const { history, match } = this.props
    const { oid } = match.params
    return (
      <div
        style={{ position: 'fixed', height: '50px', width: '100%', bottom: 0, zIndex: '9999999' }}
      >
        {!this.state.orgDetail || !this.state.orgDetail.vrLink
          ? <TabBar
            unselectedTintColor='#999'
            // tintColor="#33A3F4"
            tintColor='#2c2c2c'
            barTintColor='#fff'
          >
            {/* 第一个标签 */}
            <TabBar.Item
              title='首页'
              key='home'
              icon={
                <div
                  style={{
                    width: '22px',
                    height: '22px',
                    background: `url(${require('../images/shouye.png')}) center center /  25px 25px no-repeat`
                  }}
                />
              }
              selectedIcon={
                <div
                  style={{
                    width: '22px',
                    height: '22px',
                    background: `url(${require('../images/shouye1.png')}) center center /  25px 25px no-repeat`
                  }}
                />
              }
              selected={this.state.selectedTab.includes('/home')}
              // badge={3}
              onPress={() => {
                history.replace({
                  pathname: `/${oid}/home`
                })
                this.setState({
                  selectedTab: `/${oid}/home`
                })
              }}
              data-seed='logId'
            />
            {/* 第二个标签 */}
            <TabBar.Item
              title='专题'
              key='topic'
              icon={
                <div
                  style={{
                    width: '40px',
                    height: '22px',
                    background: `url(${require('../images/book.png')}) center center /  22px 22px no-repeat`
                  }}
                />
              }
              selectedIcon={
                <div
                  style={{
                    width: '40px',
                    height: '22px',
                    background: `url(${require('../images/book1.png')}) center center /  22px 22px no-repeat`
                  }}
                />
              }
              selected={this.state.selectedTab.includes('/topic')}
              // badge={3}
              onPress={() => {
                history.replace({
                  pathname: `/${oid}/topic`
                })
                this.setState({
                  selectedTab: `/${oid}/topic`
                })
              }}
              data-seed='logId'
            />
            {/* 第三个标签 */}
            <TabBar.Item
              title='搜索'
              key='search'
              icon={
                <div
                  style={{
                    width: '40px',
                    height: '22px',
                    background: `url(${require('../images/sousuo.png')}) center center /  22px 22px no-repeat`
                  }}
                />
              }
              selectedIcon={
                <div
                  style={{
                    width: '40px',
                    height: '22px',
                    background: `url(${require('../images/sousuo1.png')}) center center /  22px 22px no-repeat`
                  }}
                />
              }
              selected={this.state.selectedTab.includes('/search')}
              // badge={3}
              onPress={() => {
                history.replace({
                  pathname: `/${oid}/search/2`
                })
                this.setState({
                  selectedTab: `/${oid}/search/2`
                })
              }}
              data-seed='logId'
            />
          </TabBar>
          : <TabBar
            unselectedTintColor='#999'
            // tintColor="#33A3F4"
            tintColor='#2c2c2c'
            barTintColor='#fff'
          >
            {/* 第一个标签 */}
            <TabBar.Item
              title='首页'
              key='home'
              icon={
                <div
                  style={{
                    width: '22px',
                    height: '22px',
                    background: `url(${require('../images/shouye.png')}) center center /  25px 25px no-repeat`
                  }}
                />
              }
              selectedIcon={
                <div
                  style={{
                    width: '22px',
                    height: '22px',
                    background: `url(${require('../images/shouye1.png')}) center center /  25px 25px no-repeat`
                  }}
                />
              }
              selected={this.state.selectedTab.includes('/home')}
              // badge={3}
              onPress={() => {
                history.replace({
                  pathname: `/${oid}/home`
                })
                this.setState({
                  selectedTab: `/${oid}/home`
                })
              }}
              data-seed='logId'
            />
            {/* 第二个标签 */}
            <TabBar.Item
              title='专题'
              key='topic'
              icon={
                <div
                  style={{
                    width: '40px',
                    height: '22px',
                    background: `url(${require('../images/book.png')}) center center /  22px 22px no-repeat`
                  }}
                />
              }
              selectedIcon={
                <div
                  style={{
                    width: '40px',
                    height: '22px',
                    background: `url(${require('../images/book1.png')}) center center /  22px 22px no-repeat`
                  }}
                />
              }
              selected={this.state.selectedTab.includes('/topic')}
              // badge={3}
              onPress={() => {
                history.replace({
                  pathname: `/${oid}/topic`
                })
                this.setState({
                  selectedTab: `/${oid}/topic`
                })
              }}
              data-seed='logId'
            />
            {/* 第三个标签 */}
            <TabBar.Item
              title='搜索'
              key='search'
              icon={
                <div
                  style={{
                    width: '40px',
                    height: '22px',
                    background: `url(${require('../images/sousuo.png')}) center center /  22px 22px no-repeat`
                  }}
                />
              }
              selectedIcon={
                <div
                  style={{
                    width: '40px',
                    height: '22px',
                    background: `url(${require('../images/sousuo1.png')}) center center /  22px 22px no-repeat`
                  }}
                />
              }
              selected={this.state.selectedTab.includes('/search')}
              // badge={3}
              onPress={() => {
                history.replace({
                  pathname: `/${oid}/search/2`
                })
                this.setState({
                  selectedTab: `/${oid}/search/2`
                })
              }}
              data-seed='logId'
            />
            {this.renderVr()}
          </TabBar>
        }
      </div>
    )
  }
}

export default MainTabBar
