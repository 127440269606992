import React, { Fragment } from 'react'
import { Modal, Toast, Button } from 'antd-mobile'
import { getPolicy, uploadMedia, publishVideoMessageList } from 'api/document'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Photo from 'components/Photo'
import styles from './styles.less'

let mediaRecorder
let recorderFile = null
let mediaStream = null
let timer = null

class Record extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false, // 录制的显示
      currentVideoBlob: null,
      // step 1-打开摄像头状态 2-录制中  3-录制结束，可会看
      step: 0,
      recordTime: 0,
      list: [],
      detailVisible: false, // 详情的显示
      detail: null,
      submitLoading: false
    }
  }

  componentDidMount () {
    this.getList()
  }

  getList () {
    const { oid } = this.props.match.params
    publishVideoMessageList({ baseOrgId: oid }).then(res => {
      this.setState({
        list: res.data
      })
    })
  }

  viewDetail (data) {
    this.setState({
      detailVisible: true,
      detail: data
    })
  }

  getUserMedia (constraints, success, error) {
    if (navigator.mediaDevices.getUserMedia) {
      // 最新的标准API
      navigator.mediaDevices.getUserMedia(constraints).then(success).catch(error)
    } else if (navigator.webkitGetUserMedia) {
      // webkit核心浏览器
      navigator.webkitGetUserMedia(constraints, success, error)
    } else if (navigator.mozGetUserMedia) {
      // firfox浏览器
      navigator.mozGetUserMedia(constraints, success, error)
    } else if (navigator.getUserMedia) {
      // 旧版API
      navigator.getUserMedia(constraints, success, error)
    }
  }

  secondToTime (time) {
    const hour = Math.floor(time / 3600)
    const min = Math.floor(Math.floor(time % 3600) / 60)
    const second = time % 60
    const newHour = hour < 10 ? `0${hour}` : hour
    const newMin = min < 10 ? `0${min}` : min
    const newSecond = second < 10 ? `0${second}` : second
    return newHour + ' : ' + newMin + ' : ' + newSecond
  }

  openCamera () {
    this.setState({
      currentVideoBlob: null,
      submitLoading: false,
      visible: true,
      step: 1,
      recordTime: 0
    })
    if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
      // 调用用户媒体设备, 访问摄像头
      this.getUserMedia({ audio: true, video: true }, this.success, this.error)
    }
  }

  startRecord () {
    this.setState({
      step: 2
    })
    let chunks = []
    mediaRecorder = new MediaRecorder(mediaStream)
    mediaRecorder.start()
    mediaRecorder.ondataavailable = function (e) {
      chunks.push(e.data)
    }
    timer = setInterval(() => {
      if (this.state.step !== 2) {
        return
      }
      this.setState({
        recordTime: this.state.recordTime + 1
      })
    }, 1000)

    mediaRecorder.onstop = function (e) {
      recorderFile = new Blob(chunks, { type: mediaRecorder.mimeType })
      chunks = []
    }
  }

  stopRecord () {
    mediaRecorder.stop()
    clearInterval(timer)
    setTimeout(() => {
      const curUrl = URL.createObjectURL(recorderFile)
      this.setState({
        step: 3,
        currentVideoBlob: curUrl
      })
      this.cancel()
      mediaStream = null
    }, 1)
  }

  success (stream) {
    let video = document.getElementById('video')
    mediaStream = stream
    video.srcObject = stream
    video.play()
  }

  error (error) {
    console.log(`访问用户媒体设备失败${error.name}, ${error.message}`)
  }

  closeModal () {
    this.setState({
      visible: false,
      step: 0,
      currentVideoBlob: null,
      recordTime: 0
    })
    clearInterval(timer)
    this.cancel()
  }

  cancel () {
    // 关闭摄像头和声音
    if (mediaStream) {
      mediaStream.getTracks()[0].stop()
      mediaStream.getTracks()[1].stop()
    }
  }

  randomString (len) {
    len = len || 32
    var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    var maxPos = chars.length
    var pwd = ''
    for (let i = 0; i < len; i++) {
      pwd += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return pwd
  }

  submit () {
    const file = new File([recorderFile], Date.now(), { lastModified: Date.now() })
    var pre = this.randomString()
    const { oid } = this.props.match.params
    getPolicy({ baseOrgId: oid }).then(res => {
      if (res.code !== 200) {
        Toast.fail('阿里云获取证书失败,可再次提交', 3)
        return
      }
      const { accessid, dir, host } = res.data
      const params = {
        ...res.data,
        OSSAccessKeyId: accessid,
        success_action_status: 200,
        key: `${dir}media/${pre}.mp4`
      }
      let formData = new FormData()
      for (const key in params) {
        const value = params[key]
        formData.append(key, value)
      }
      formData.append('file', file)
      this.setState({ submitLoading: true })
      axios.post(host, formData).then(val => {
        if (val.status === 200) {
          const imageUrl = `${params.host}/${params.key}`
          const uploadMediaParams = {
            baseOrgId: oid,
            state: '',
            thumbnailUrl: imageUrl + '?x-oss-process=video/snapshot,t_300,f_jpg',
            playUrl: imageUrl
          }
          uploadMedia(uploadMediaParams).then(x => {
            this.setState({ submitLoading: false })
            if (res.code === 200) {
              Toast.success('视频留言已提交 待管理员审核后可查看', 3)
              this.closeModal()
              this.getList()
            }
          })
        }
      })
    })
  }

  renderVideoArea () {
    const { step, recordTime } = this.state
    if (step === 1) {
      return <Fragment>
        <video ref='video' id='video' width='100%' muted />
        <div>
          {this.secondToTime(recordTime)}
        </div>
        <div
          type='primary'
          size='small'
          className='text-white radius-middle p-h-l'
          style={{ background: '#A4855B', height: '40px', lineHeight: '40px' }}
          onClick={() => this.startRecord()}
        >
          开始录制
        </div>
      </Fragment>
    } else if (step === 2) {
      return <Fragment>
        <video ref='video' id='video' width='100%' muted />
        <div>
          <span className='text-danger'>● </span>{this.secondToTime(recordTime)}
        </div>
        <div
          type='primary'
          size='small'
          className='text-white radius-middle p-h-l'
          style={{ background: '#FF4D4D', height: '40px', lineHeight: '40px' }}
          onClick={() => this.stopRecord()}
        >
          停止录制
        </div>
      </Fragment>
    }
  }

  initVideo (e) {
    const audio = e.target
    const audioDuration = audio.duration
    if (audioDuration === Infinity) {
      audio.currentTime = 1e101
    // 先让当前时间拉到最后，获取时长，再重新播放，需要配合循环
    }
  }

  render () {
    const { step, currentVideoBlob, recordTime, list, detailVisible, visible, detail, submitLoading } = this.state
    return (
      <div style={{ height: 'calc(100vh - 94px)' }} className='flex-column p-l bg-lighter Record'>
        <div style={{ overflow: 'auto' }} className='flex1 '>
          {/* 放视频列表 */}
          { list.map(item => {
            return <div className='flex-row bg-white m-b-l' key={item.id} onClick={() => this.viewDetail(item)}>
              <div style={{ width: '120px' }}>
                <Photo src={item.thumbnailUrl} border />
              </div>
              <div className='p-l flex-column main-between flex1'>
                <div className='text-dark text-line-3'>{item.state}</div>
                <div className='h6'>{item.recordTime ? item.recordTime.split('T')[0] : ''}</div>
              </div>
            </div>
          }) }
        </div>
        <div className='flex-row p-t-m main-center'>
          <div
            type='primary'
            size='small'
            className='text-white radius-middle p-h-l p-v-m'
            style={{ background: '#A4855B' }}
            onClick={() => this.openCamera()}
          >
            我要留言
          </div>
        </div>
        <Modal
          visible={detailVisible}
          transparent
          title=' '
          closable
          // popup
          animationType='up'
          style={{ width: '95vw', top: '-20px' }}
          onClose={() => this.setState({ detailVisible: false })}
          footer={[]}
        >
          {detail
            ? <div style={{ height: 'calc(100vh - 190px)' }} className='m-t-m flex-column'>
              <video
                src={detail.playUrl}
                autoPlay
                loop
                width='100%'
                controls='controls'
                onLoadedMetadata={(e) => this.initVideo(e)}
              />
              <div style={{ overflow: 'auto' }} className='flex1 m-t-m h5 text-left'>
                <div className='text-dark'>{detail.state}</div>
                <div className='h6 p-t-m'>{detail.recordTime ? detail.recordTime.split('T')[0] : ''}</div>
              </div>
            </div>
            : null
          }
        </Modal>
        <Modal
          visible={visible}
          title=' '
          transparent
          closable
          className={styles.modal_style}
          style={{ width: '80vw', height: '85vw' }}
          onClose={() => this.closeModal()}
          footer={[]}
        >
          <div className='p-l flex-column main-between' style={{ width: '100%', height: '95%' }}>
            { this.renderVideoArea() }
            { step === 3 ? <Fragment>
              <video
                src={currentVideoBlob}
                autoPlay
                loop
                width='100%'
                controls='controls'
                onLoadedMetadata={(e) => this.initVideo(e)}
              />
              <div>
                {this.secondToTime(recordTime)}
              </div>
              <div className='flex-row main-between cross-center'>
                <div
                  type='primary'
                  size='small'
                  className='radius-middle m-r-m flex1'
                  style={{ border: '1px solid #A4855B', color: '#A4855B', height: '40px', lineHeight: '40px' }}
                  onClick={() => this.openCamera()}
                >
                  撤销
                </div>
                <div className='text-white radius-middle m-l-m flex1'>
                  <Button
                    type='primary'
                    size='small'
                    loading={submitLoading}
                    style={{
                      background: '#A4855B',
                      height: '40px',
                      lineHeight: '40px'
                    }}
                    onClick={() => this.submit()}
                  >
                    <span style={{ fontSize: '15px' }}>{ submitLoading ? '提交中...' : '提交' }</span>
                  </Button>
                </div>
              </div>
            </Fragment> : null }
          </div>
        </Modal>
      </div>
    )
  }
}

export default withRouter(Record)
