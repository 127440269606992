
const video = {
  // 是否是音频
  isAudio (url) {
    if (url.includes('.cd') ||
				url.includes('.wave') ||
				url.includes('.aiff') ||
				url.includes('.mpeg') ||
				url.includes('.mp3') ||
				url.includes('.m4a') ||
				url.includes('.midi') ||
				url.includes('.wma') ||
				url.includes('.realaudio') ||
				url.includes('.vqf') ||
				url.includes('.oggvorbis') ||
				url.includes('.amr') ||
				url.includes('.ape') ||
				url.includes('.flac') ||
				url.includes('.aac')) {
      return true
    } else {
      return false
    }
  }
}

export default video
