/*
滚动加载列表

获取远程数据必备函数
getListData(){}

记录数据和页码等必备参数
this.setState({
  loaded : false,
  pageindex : 1,
  pagesize : 10,
  data : [],
  more : true
})

根元素必须有：ref='scrollcontainer'
<article ref='scrollcontainer'></article>

数据的注入在componentDidMount完成
*/

// 递归弹出历史数据, 根据路由地址查找
const popData = (pathname) => {
  let InfiniteQueue = JSON.parse(sessionStorage.InfiniteQueue)
  if (!InfiniteQueue.length) return false
  let data = InfiniteQueue.pop()
  sessionStorage.setItem('InfiniteQueue', JSON.stringify(InfiniteQueue))
  return data.pathname === pathname ? data : popData(pathname)
}

const scrollTo = (y) => {
  document.body.scrollTop = document.documentElement.scrollTop = y
  // console.log('scrollTo', y)
}

let InfiniteScrollMixin = {
  bottomOffset: 20, // 阈值
  loadDelay: 3, // 触发延时

  componentDidMount () {
    this.handleInfiniteScroll = this.handleInfiniteScroll.bind(this)
    this.pushDataQueue = this.pushDataQueue.bind(this)
    let InfiniteQueue = sessionStorage.InfiniteQueue

    if (!InfiniteQueue) {
      sessionStorage.setItem('InfiniteQueue', JSON.stringify([]))
    } else {
      // 如果没有InfiniteQueue缓存键名，先设置一个
      InfiniteQueue = JSON.parse(InfiniteQueue)
    }

    // 进入页面时优先取历史数据
    const { pathname } = this.props.location
    const { action } = this.props.history
    // console.log(pathname, action, InfiniteQueue, InfiniteQueue.length)

    // 取回这个页面的历史数据
    // if (action === 'POP' && !!InfiniteQueue && !!InfiniteQueue.length) {
    if (
      action === 'POP' &&
      !!InfiniteQueue &&
      !!InfiniteQueue.length
    ) {
      let data = popData(pathname)
      // console.log('退回:', pathname)
      if (data) {
        // console.log('mixinDidMount', data)
        // 取回数据后更新state
        this.setState(
          data.state,
          () => setTimeout(function () {
            scrollTo(data.scrollY)
          }, 2)
        )
      } else {
        this.getListData && this.getListData()
      }
    } else {
      // 正常，非pop进入页面的，有_startFromInfiniteScroll则执行_startFromInfiniteScroll
      this.getListData && this.getListData()
    }
    if (!('scrollcontainer' in this.refs)) {
      alert('根元素未绑定ref="scrollcontainer"') // eslint-disable-line
      return
    }
    window.addEventListener('scroll', this.handleInfiniteScroll)
  },

  componentWillUnmount () {
    // 出页面时把当前页面数据压到队列中
    this.pushDataQueue()
    window.removeEventListener('scroll', this.handleInfiniteScroll)
  },

  // 页面卸载，将页面的数据压入到缓存队列中
  pushDataQueue () {
    let InfiniteQueue = JSON.parse(sessionStorage.InfiniteQueue)
    // 缓存数据队列，后进先出原则。push压入,pop取出
    // console.log('push', this.state)
    let data = {
      state: this.state,
      scrollY: window.pageYOffset,
      pathname: this.props.location.pathname
    }
    // console.log('333333333333 pushDataQueue', data);
    InfiniteQueue.push(data)
    sessionStorage.setItem('InfiniteQueue', JSON.stringify(InfiniteQueue))
  },

  // 滚动处理
  handleInfiniteScroll () {
    // 如果没有更多，或者正在加载，跳出
    if (!this.state.more || !this.state.loaded) return

    // 如果没有给根元素绑定scrollcontainer，跳出
    let container = this.refs.scrollcontainer
    if (!container) return

    let containerHeight = container.clientHeight
    let windowHeight = window.innerHeight
    let scrollY = window.scrollY
    let bottomH = containerHeight - windowHeight - scrollY
    if (bottomH <= this.bottomOffset && !!this.state.more && this.state.loaded) {
      this.setState({
        loaded: false
      }, () => setTimeout(() => {
        this.getListData && this.getListData()
      }, this.loadDelay))
    }
  }
}

export default InfiniteScrollMixin
