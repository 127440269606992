import React from 'react'
import { ActivityIndicator } from 'antd-mobile'
import './LoadingMixin.less'

const CLASS = 'loading-page p-m text-center'

/*
预加载动画
renderNoResult : 配合InfiniteScrollMixin使用，显示无数据结果
renderListPreload : 配合InfiniteScrollMixin使用，显示列表加载loading
renderPagePreload : 显示页面加载loading
*/

let LoadingMixin = {

  componentDidMount () {
    this.renderNoResult = this.renderNoResult.bind(this)
    this.renderListPreload = this.renderListPreload.bind(this)
    this.renderPagePreload = this.renderPagePreload.bind(this)
  },

  // 加载完成没有数据
  renderNoResult (_element = '没有结果', _style) {
    if (this.state.loaded && !this.state.list.length) {
      return (
        <div className={`${CLASS} is-first-page`} style={_style || null}>
          <div className='text-light'>
            {/* <div><Icon type={require('images/no_result.svg')} style={{ fill: '#576b95' }} className='no-result-icon' /></div> */}
            { _element }
          </div>
        </div>
      )
    }
  },

  // 列表页加载动画
  renderListPreload (_style) {
    const { list, loaded, more } = this.state
    if (!this.state || !list) {
      console.warn('renderListProload需要this.state.list，请检查您的页面state是否有该属性')
      return
    }

    let isFirstScreen = !loaded && !list.length
    // 首屏加载loading图标在页面垂直居中
    let _className = isFirstScreen ? CLASS + ' is-first-page' : CLASS

    // 加载完成没有数据
    if (loaded && !list.length) {
      return null
    }
    // console.log(more)

    // 没有更多数据
    if (!more) {
      return (
        <div className={_className} style={_style || null}>
          <div className='text-light text-wing'>
            没有更多
          </div>
        </div>
      )
    }

    return (
      <div className={_className} style={_style || null}>
        <div className='text-light'>
          <ActivityIndicator text='加载中...' />
        </div>
      </div>
    )
  },

  // 非列表页加载动画
  renderPagePreload (_style) {
    let _className = CLASS + ' is-first-page'
    return (
      <div className={_className} style={_style || null}>
        <div>
          <ActivityIndicator text='加载中...' />
        </div>
      </div>
    )
  }
}
export default LoadingMixin
